.stockForm{
    background: #fff;
    padding-bottom: 2rem;
}
.stockForm .formLabel{
    font-size: 12px !important;
    margin-top: 5px !important;
}
.closeIcon{
    width: 30px;
    background: red;
    color: #fff;
    height: 30px;
    padding: 6px 8px;
    font-size: 18px;
    margin: 8px;
    border-radius: 4px;
    float: left;
    cursor: pointer;
}
.stockTitle{
    font-size: 18px;
    font-weight: 600;
    color: #43425D;
    display: inline-block;
    margin: 15px;
}
.smallUploderImg {
    margin: 0px 10px;
    display: block;
    width: 30px;
    float: right;
}
.smallUploadContainer {
    text-align: center;
    height: 35px;
    background: #f4fcfd;
    padding: 5px;
}
.smallUploader {
    position: absolute;
    cursor: pointer;
    width: 95%;
    top: 0;
    z-index: 9;
    height: 50%;
    left: 15px;
    opacity: 0;
}
.smallUploaderBtn {
    float: right;
    margin-right: 20px ;
    padding: 0rem 1.6rem !important;
    font-size: .64rem;
}
input{
    border-radius: 2px !important
}
input::placeholder,textarea::placeholder{
    color: #A4A3B0 !important;
    font-weight: 500;
    font-size: 14px;
}
input:focus{
    outline: none !important;
    box-shadow: none !important;
}
.stockForm .ant-select-selector{
    height: 35px !important;
    margin-bottom: 4px !important;
}
.stockForm .ant-select-selection-search-input{
    height: 35px !important;
}
.ant-input::placeholder {
    color: #A4A3B0 !important;
    font-size: 13px !important;
    font-weight: 400;
}
.stockForm .ant-select-selection-placeholder,.ant-select-selection-item{
    color: #434347 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding-top: 5px !important
}
.package{
    border-radius: 20px;
    background: #C3D7F2;
    padding: 10px 0px;
    margin-bottom: 1rem;
}
.packageInput{
    height: 22px;
    border-radius: 18px !important;
    margin-bottom: 0px;
    margin-top: 3px;
    background: transparent;
    border: 1px solid #aaa;
    font-size: 11px;
    text-align: center;
}
.packageInput::placeholder {
    color: #A4A3B0 !important;
    font-size: 13px !important;
    font-weight: 400;
}
.packageLabel{
    font-size: 13px;
    font-weight: 400;
    padding: 5px;
}
.installmentInput{
    border: none;
    color: red;
    font-size: 11px;
    text-align: center;
    background: transparent;
}
.installmentInput:focus{
    background: transparent;
    border: none;
}
.addPackage{
    color: #31313F;
    font-size: 20px;
    font-weight: 600;
    margin: 11px;
    position: absolute;
    top: 30px;
    left: 58px;
    cursor: pointer;
}
.removePackage{
    width: 30px;
    top: -8px;
    left: 8px;
    position: absolute;
    background: #F0F2F5;
    cursor: pointer;
    padding: 4px;
    border-radius: 5px;
}