body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Cairo";
}
.categoryInfo {
  padding-top: 10px;
  text-align: right;
}
.Category .ant-select-selector {
  height: 40px !important;
}
.Category .ant-select-selection-search-input {
  height: 40px !important;
}
.ant-input::placeholder {
  color: #a4a3b0 !important;
  font-size: 13px !important;
  font-weight: 400;
}
.Category .ant-select-selection-placeholder,
.ant-select-selection-item {
  color: #434347 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  padding-top: 5px !important;
}
.ant-input-prefix {
  color: #a4a3b0 !important;
}
.Category .ant-input-affix-wrapper-lg :hover,
.Category .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border: none !important;
}
.tabelDev table.table td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  text-align: center;
  font-weight: 400;
  border: 1px solid #000;
}
.tabelDev table.table th {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  text-align: center;
  font-weight: 400;
  border: 1px solid #000;
}
.tabelDev table.table {
  background: #fff !important;
}
.tabelDev .table .thead-light th {
  color: #000 !important;
  background-color: #dbdada !important;
  border-color: #dbdada !important;
  border: 1px solid #000;
  font-weight: 600;
  font-size: 14px;
}
