/* @import url("https://fonts.googleapis.com/css?family=Cairo&display=swap");
* {
  font-family: "Cairo";
} */
.previewRaduis img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.previewRaduis .removeImg {
  top: 9px;
  position: absolute;
  left: auto !important;
  color: red;
  font-size: 12px;
  margin-left: -35px !important;
  cursor: pointer;
}
.clientProduct {
  height: 40px;
  border-radius: 10%;
  width: 40px;
  border: 1px solid gray;
}
.clientForm .form-control:disabled,
.clientForm .form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}
.clientProductCount {
  display: block;
  font-weight: 500;
  position: relative;
  bottom: 40px;
  background: #dedede;
  width: 16px;
  height: 16px;
  margin-left: 0px;
  border-radius: 100%;
  font-size: 12px;
  padding: 0px 4px;
}
.showImge {
  width: 20px !important;
  height: 20px !important;
}
.hash-new {
  direction: rtl;
  margin-bottom: 2px;
  border: 1px solid gray;
}

.hash-btn {
  margin: auto;
  padding: 11px 20px;
  height: 35px;
  width: 98%;
  font-size: 14px;
  font-weight: 600;
  background: #4f73ac;
  color: #fff;
}
.hzr {
  background: #b8190d;
}
.unblock {
  background: #329832;
}
