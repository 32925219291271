.newTitle {
  font-size: 16px;
  color: #4285f4;
  font-family: system-ui;
  font-weight: 400;
  margin: 1rem 2rem 1rem 0.5rem;
  display: inline-block;
}

.productNum {
  display: block;
  font-weight: 500;
  position: relative;
  bottom: 24px;
  background: #dedede;
  width: 22px;
  height: 22px;
  margin-left: 35px;
  border-radius: 100%;
  font-size: 14px;
  padding: 1px 7px;
}
.salesManProduct {
  height: 55px;
  border-radius: 5px;
  width: 55px;
  border: 1px solid rgb(67, 66, 93);
  margin-bottom: 10px;
}
.receivable {
  width: 100%;
  /* overflow-y: scroll; */
  padding: 0% 0%;
  height: 189px;
}

.receivable::-webkit-scrollbar {
  width: 10px;
}
.receivable::-webkit-scrollbar-track {
  background: #dedede;
  border-radius: 6px;
}
.receivable::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 100px;
  border: 1px solid #fff;
}
.receiveBox {
  width: 48%;
  background: #4f73ac;
  color: #ffffff;
  padding: 5px;
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  margin: 1px;
  font-size: 18px;
}
.receiveBox1 {
  width: 32%;
  background: #4f73ac;
  color: #ffffff;
  padding: 5px;
  white-space: nowrap;
  display: inline-block;
  border-radius: 5px;
  margin: 1px;
  font-size: 15px;
}
.topReceiveBox {
  margin-bottom: 10px;
  text-align: center;

  font-weight: 500;
  font-family: system-ui;
  float: right;
}
.bottomReceiveBox {
  margin-bottom: 0px;
  text-align: center;
  font-weight: 800;
  float: left;
}
.newSpan {
  color: #aaa;
  font-weight: 500;
  box-shadow: 0px 1px 4px 0px #dedede;
  height: 35px;
  display: inline-block;
  width: 140px;
  padding: 7px;
  position: absolute;
  left: 80px;
  cursor: pointer;
  background-color: #fff;
}
.fromSpan {
  font-size: 20px;
  float: right;
  margin-right: 15%;
  color: #aaa;
}
.ant-picker-input > input:hover {
  cursor: pointer;
}
.ant-picker {
  z-index: 7;
  opacity: 0;
  cursor: pointer;
}
.modal-title {
  font-weight: 600;
  color: dimgrey;
}
