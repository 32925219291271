.adsForm{
    background: #fff;
    padding-bottom: 4rem;
    margin: 0rem 20% 5rem;
}
.adsForm .removeImg{
    left: 53%;
}
.stockForm .formLabel{
    font-size: 12px !important;
    margin-top: 5px !important;
}

input{
    border-radius: 2px !important
}
input::placeholder{
    color: #A4A3B0 !important;
}
input:focus{
    outline: none !important;
    box-shadow: none !important;
}
.ant-select-selector{
    height: 35px !important;
    margin-bottom: 4px !important;
}
.ant-select-selection-search-input{
    height: 35px !important;
}
.ant-input::placeholder {
    color: #A4A3B0 !important;
    font-size: 13px !important;
    font-weight: 400;
}
.ant-select-selection-placeholder,.ant-select-selection-item{
    color: #434347 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding-top: 5px !important
}
.ant-select-multiple .ant-select-selection-item-remove {
    margin-top: -6px !important;
}
.ant-select-multiple .ant-select-selector{
    min-height: 40px !important;
    margin-bottom: 10px !important;
}
.ant-select-multiple .ant-select-selection-item-content {
    margin-top: -3px !important;
}
.ant-select-multiple .ant-select-selection-item{
    margin-top: 0px !important;
}
.adsForm .ant-select-multiple .ant-select-selection-placeholder {
    font-size: 16px !important
}


.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #00FF00 !important;
    border-color: #00FF00 !important;
}
.ant-checkbox-inner {
    width: 22px !important;
    height: 22px !important;
}
.ant-checkbox-inner::after {
    width: 7px !important;
    height: 12px !important;
}
