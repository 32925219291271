
.tabelNewReciept table.table td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    text-align: center;
    font-weight: 600;
    border: 2px solid #000 !important;
    
}
.tabelNewReciept table.table th {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    text-align: center;
    font-weight: 600;
    border: 2px solid #000 !important;
}
.tabelNewReciept table.table {
    background:#fff !important;
    border:none !important;
    margin-bottom:1px
}
.tabelNewReciept .table .thead-light th {
    color: #000 !important;
    background-color: #ffff !important;
    border-color: #000 !important;
    border: none !important;
    font-weight: 600;
    font-size: 14px;
}

.tabelNewReciept2 table.table td {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    text-align: center;
    font-weight: 600;
    border: none !important;
}
.tabelNewReciept2 table.table th {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    text-align: center;
    font-weight: 600;
    border: none !important;
}
.tabelNewReciept2 table.table {
    background:#fff !important;
    border:none !important;
    margin-bottom:2px
}
.tabelNewReciept2 .table .thead-light th {
    color: #000 !important;
    background-color: #ffff !important;
    border: none !important;
    font-weight: 600;
    font-size: 14px;
}
.tabelNewReciept2 tbody{
    height:285px !important;
}
