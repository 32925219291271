.categoryCard{
    /*border: 1px solid #aaaaaa;*/
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 1rem;
    cursor: pointer;
}
.categoryInfo{
    padding-top: 15px;
    text-align: right;
}
.categoryImg{
    text-align: end;
}
.categoryImg img{
    width: 80px;
    margin-top: 13px;
}
.addButton{
    width: 45px;
    height: 45px;
    background: #43425D;
    padding: 5px 11px;
    color: #ffff;
    font-size: 26px;
    float: right;
    border-radius: 50%;
    cursor: pointer;
    margin: 0rem 1rem;
}
.categoryTop{
    width: 100%;
    height: 60px;
    margin-bottom: 2rem;
}
.ant-popover-buttons .ant-btn-primary{
    background-color: red;
    border-color: red;
}
.MuiPaginationItem-page.Mui-selected {
    background-color: #3B86FF !important;
    border-radius: 0px !important;
    color: #fff !important;
}
.MuiPagination-ul {
    margin: 0;
    background: #fff !important;
    box-shadow: 1px 1px 3px 0px #dedede !important;
}
.MuiPaginationItem-page:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0px !important;
}
.noData{
    text-align: center;
    margin: 6rem auto;
    color: #43425D;
    font-size: 18px;
}
.modal-header .close {
    padding: 5px 12px !important;
    margin: 0rem -1rem -1rem 1rem !important;
    left: 4px;
    position: absolute;
    border-radius: 5px;
    background: red;
    color: #fff;
}
.modal-title {
    margin: auto;
}
.modal-header{
    border: none !important;
}
.formLabel{
    padding: 5px;
    font-weight: 600;
    color: #43425D !important;
}
.uploadContainer{
    text-align: center;
    height: 180px;
    background: #f4fcfd;
    padding: 1rem;
}
.uploderImg{
    margin: auto;
    display: block;
    width: 80px;
    margin-bottom: 1rem;
}
.uploader{
    position: absolute;
    cursor: pointer;
    width: 95%;
    top: 0;
    z-index: 9;
    height: 100%;
    left: 15px;
    opacity: 0;
}
.removeImg{
    top: 10px;
    position: absolute;
    left: 60%;
    color: red;
    font-size: 20px;
}
.hiddenButton{
    position: absolute;
    width: 92%;
    left: 4%;
    height: 76%;
    top: 0px;
    opacity: 0;
    z-index: 2;
}