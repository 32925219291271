.transaction{
    background-color: #fff;
    min-height: 480px;
}
.smallAddButton{
    width: 25px;
    height: 25px;
    background: #43425D;
    padding: 3px 6px;
    color: #ffff;
    font-size: 14px;
    float: right;
    border-radius: 50%;
    cursor: pointer;
    margin: 21px 10px;
}