.theNotifsTabs{
    background: #ffffff;
    height: 600px;
    box-shadow: 1px 1px 4px #dedede;
}
.theNotifss{
    height: 500px;
    overflow-y: auto
}
.theNotifss::-webkit-scrollbar {
    width: 14px;
}
  
.theNotifss::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
.theNotifss::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}
.Notifs .ant-tabs-nav {
    width: 100% !important;
    background: #F0F2F5;
}
.Notifs .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    width: 24.9% !important ;
    height: 85px ;
    text-align: center ;
    margin-right: 1px !important;
    line-height: 60px !important;
    font-size: 18px ;
    color: #43425D !important;
    font-weight: 500 ;
    background: #ffffff;
    font-family: system-ui;
}
.Notifs .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: #89bff16b;
}
.Notifs .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
    width: 10px;
    height: 10px;
    background: tan;
    border-radius: 50%;
    float: right;
    position: initial;
}
.theNotifs{
    width: 100%;
    background: #F0F2F5;
    min-height: 90px;
    border: 1px solid #dedede;
    border-top: none;
    cursor: pointer;
}
.theNotifsInfoRight{
    float: right;
    width: 67%;
    text-align: end;
    padding: 6px 30px;
    font-family: system-ui;
    height: 100%;
}
.theNotifsInfoLeft{
    float: left;
    width: 33%;
    height: 100%;
    font-weight: 400;
    color: rgb(144, 143, 154);
    font-size: 16px;
    padding: 25px 15px;
}
.theNotifs .ant-tabs-bar {
    margin: 0  !important;
}
.theNotifsPackage{
    background: rgba(195, 215, 242, 0.56);
    padding: 10px 0px;
    width: 80%;
    margin: 1rem 0rem;
    box-shadow: 3px 5px 7px rgba(195, 215, 242, 0.56);
}
.theNotifsPackageInput{
    border: none;
    color: #478DFF;
    font-size: 15px;
    text-align: center;
    margin-top: -4px;
    background: transparent;
}
.theNotifsPackageLabel {
    font-size: 12px;
    font-weight: 600;
    padding: 6px;
}
.theNotifsType{
    width: 20%;
    float: right;
}
.theNotifsType p{
    text-align: right;
    color: #478DFF;
    font-size: 18px;
    font-family: system-ui;
    font-weight: 400;
    margin-top: 1rem;
}
.usersCheck .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    float: left;
    font-size: 14px;
    font-weight: 400;
    color: #4F73AC;

}
.usersCheck .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}
.usersCheck .ant-checkbox-inner {
    width: 18px !important;
    height: 18px !important;
}
.usersCheck .ant-checkbox-inner::after {
    width: 5px !important;
    height: 10px !important;
}
.Notifs .modal-title {
    margin: auto;
    width: 96%;
}
.Notifs .modal-header {
    padding-bottom: 0;
}

