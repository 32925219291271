
.productsContainer{
    width: 100%;
    overflow-x: scroll;
    white-space:nowrap; 
    margin-top: 3rem;
}
.productsContainer::-webkit-scrollbar {
    width: 12px;
}
.productsContainer::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 6px;
}
.productsContainer::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}
.myProduct{
    width: 300px;
    background: #fff;
    padding-top: 20px;
    white-space:nowrap; 
    display: inline-grid;
    margin: 15px;
    
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}
.form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: 1;
}

.productInfoLeft{
    padding: 6px;
    box-shadow: 2px 5px 12px #dadada;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: 10px;
    height: 36px;
}
.productInfoRight{
    background: #43425D;
    border-top-right-radius: 20px;
    color: #fff;
    padding: 6px;
    box-shadow: 4px 5px 12px #dadada;
    border-bottom-right-radius: 20px;
    margin-top: 10px;
}
.infoTitle{
    text-align: right;
    margin-bottom: 0px;
    padding-right: 10px;
}
.infoData{
    text-align: center;
    color: rgba(168, 8, 107, 0.767);
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 14px;
}
.productTopInfo{
    font-weight: 600;
    margin-bottom: 10px;
    text-align: right;
}
.productTopImg{
    text-align: left;
}
@media (max-width: 576px){
    .productTopInfo{
        text-align: center;
    }
    .productTopImg{
        text-align: center;
    }
}
.ClientProducts .package{
    border-radius: 4px;
    background: #c3d7f28f;
    margin-top: 1rem;
    cursor: pointer;
}
.ClientProducts .packageLabel{
    font-weight: 600;
}

.newSpanM{
    color: #aaa;
    font-weight: 500;
    box-shadow: 0px 1px 4px 0px #dedede;
    height: 35px;
    display: inline-block;
    width: 140px;
    padding: 7px;
    position: absolute;
    right: 15px;
    cursor: pointer;
    background-color: #fff;
}
.ant-message {
    z-index: 10000 !important;
}
.topMulti{
    box-shadow: 2px 5px 12px #dadada;
    margin: 10px 0px;
    padding-bottom: 11px;
    border-radius: 14px;

}
.productInfoRightTop{
    margin-top: 0px;
    background: #191580;
    border-top-right-radius: 12px;
    color: #fff;
    padding: 6px;
}
.productInfoLeftTop{
    padding: 6px;
    border-top-left-radius: 12px;
    height: 36px;
    background: #b1adad;
}
.productInfoCenter{
    padding: 6px;
    margin-top: 10px;
    height: 36px;
    text-align: center;
    background: #dadada;
}
.tabelOrders table.table td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    text-align: center;
    font-weight: 600;
    border: 2px solid #000;
}
.tabelOrders table.table th {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    text-align: center;
    font-weight: 600;
    border: 2px solid #000;
}
.tabelOrders table.table {
    background:#fff !important
}
.tabelOrders .table .thead-light th {
    color: #000 !important;
    background-color: #dbdada !important;
    border-color: #000 !important;
    border: 2px solid #000;
    font-weight: 600;
    font-size: 14px;
}

.tabelNewReciept table.table td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    text-align: center;
    font-weight: 600;
    border: 2px solid #000;
}
.tabelNewReciept table.table th {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    text-align: center;
    font-weight: 600;
    border: 2px solid #000;
}
.tabelNewReciept table.table {
    background:#fff !important;
    border:none;
    margin-bottom:1px
}
.tabelNewReciept .table .thead-light th {
    color: #000 !important;
    background-color: #ffff !important;
    border-color: #000 !important;
    border: none;
    font-weight: 600;
    font-size: 14px;
}