.hash-row {
  background: red;

  border-radius: 3% 3% 0 0;
}
.categoryCard {
  padding: 0;
  border-radius: 2%;
  overflow: hidden;
}

.hrow:first-of-type {
  background: #f44336;
}
.rowblue {
  background: #37598c;
  border-top: 1px solid gray;
}
.categoryInfo {
  padding-top: 15px;

  color: aliceblue;
}
