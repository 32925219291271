.clientTitle{
    background: #F5F7F8;
    text-align: center;
    padding: 10px;
    color: #333a;
    font-weight: 600;
    font-size: 14px;
    font-family: system-ui;
    margin-bottom: 0;
}
.clientDetails{
    background: #fff;
    text-align: center;
    padding: 10px;
    margin-bottom: 2px;
    cursor: pointer;
}
