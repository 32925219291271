
.categoryInfo{
    padding-top: 10px;
    text-align: right;
}
.Category .ant-select-selector{
    height: 40px !important;
}
.Category .ant-select-selection-search-input{
    height: 40px !important;
}
.ant-input::placeholder {
    color: #A4A3B0 !important;
    font-size: 13px !important;
    font-weight: 400;
}
.Category .ant-select-selection-placeholder,.ant-select-selection-item{
    color: #434347 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding-top: 5px !important
}
.ant-input-prefix{
    color: #A4A3B0 !important;
}
.Category .ant-input-affix-wrapper-lg :hover,
.Category .ant-input-affix-wrapper-focused{
   
    box-shadow: none !important;
    border: none !important;
}
