.customColor-text {
  color: #31313f !important;
}
.ant-select-dropdown {
  min-width: 180px !important;
}
.ant-select-tree-list::-webkit-scrollbar {
  width: 14px !important;
}

.ant-select-tree-list::-webkit-scrollbar-track {
  background: var(--scrollbarBG) !important;
}
.ant-select-tree-list::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) !important;
  border-radius: 6px !important;
  border: 3px solid var(--scrollbarBG) !important;
}
.navbar {
  min-height: 60px !important;
  padding: 0px;
  /*background-color: #5a8e7d !important;*/
  box-shadow: none;
  /*color: #fff !important;*/
  padding: 0rem !important;
  padding-bottom: 0rem;
  margin: 0px;
  margin-top: 0px;
  box-shadow: none !important;
}
.nav-item {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 16px;
}
.navbar-nav {
  padding-left: 0% !important;
}
.navbar-nav .nav-item .nav-link {
  color: #fff !important;
  transition: 0.35s;
}
.navbar-brand {
  color: rgba(0, 0, 0, 0.9);
  padding: 0px 60px !important;
  margin-right: 0px;
}
.navbar-brand img {
  height: 75px !important;
  margin-top: -26px;
}
.nav-item.active > .nav-link {
  background-color: transparent !important;
}
.navbar hr {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 2px solid #d6d4d4;
  margin-left: 9px;
  width: 92%;
}
.nav-item .ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
  width: 20rem;
  border-radius: 8px;
  border: none;
}
.ant-input-affix-wrapper-lg :hover,
.ant-input-affix-wrapper-focused {
  border-color: #31313f !important;
  box-shadow: none !important;
  border: none;
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.mr-auto {
  margin-right: 1rem !important;
}
.rightUl .nav-item {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.nav-item .btn-secondary.dropdown-toggle,
.nav-item .btn-default,
.nav-item .btn-secondary.dropdown-toggle:focus {
  background-color: transparent !important;
}
.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  background-color: transparent !important;
}
.nav-item .btn {
  margin: 0px !important;
  border: 0 !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.nav-item-icon .btn {
  border-radius: 50%;
  width: 45px !important;
  height: 45px !important;
}
.nav-item .MuiAvatar-circle {
  display: inline-block;
}
.nav-item .dropdown-toggle::after {
  padding-top: 0.8rem !important;
  margin-left: 10px !important;
  display: none;
}
.navbar .anticon-down {
  font-size: 10px;
  display: inline-flex;
}
.hasProfile .dropdown-menu {
  transform: translate3d(-104px, 65px, 0px) !important;
  border-radius: 4px;
}
.navbar
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
}
.navbar .ant-select-arrow {
  color: #31313f;
  font-size: 10px;
}
.badge {
  display: inline-block;
  padding: 0.3em 0.4em !important;
  font-size: 100% !important;
  font-weight: 700;
  border-radius: 1.25rem !important;
}
.nav-item .btn .badge {
  top: -11px !important;
}
.nav-item .fa {
  font-size: 16px !important;
  color: #a4a3b0 !important;
  padding-top: 4px;
}
.nav-item .ml-2 {
  margin-left: -0.5rem !important;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 1.5rem;
  overflow: hidden;
  width: 70%;
}
.hasProfile .dropdown-menu::before {
  display: inline-block;
  margin-left: 6.7em;
  vertical-align: 0.255em;
  content: "";
  position: absolute;
  margin-top: -1.34rem;
  border-right: 1em solid transparent;
  border-bottom: 1.3em solid #fff;
  border-left: 1em solid transparent;
}
.dropdown-menu .active {
  color: #000;
}
.dropdown-item {
  line-height: initial !important;
}
.dropdown-item:focus {
  background-color: #c1b875;
}
.navbar .dropdown-menu a {
  padding: 0px !important;
  font-size: 0.9375rem;
  font-weight: 300;
  color: #000 !important;
}
.sub-nav {
  background: #5a8e7d;
  color: #fff;
  padding: 1.3rem;
  font-size: 18px;
  font-weight: 400;
  padding-left: 3rem;
  overflow: hidden;
}
.sub-nav .nav-item {
  margin-left: 12px;
  margin-right: 12px;
  font-size: 16px;
}

.rightUl {
  /*display: contents;*/
  padding-left: 18% !important;
}
.headerUsername {
  float: left;
  padding: 11px;
  min-width: 50px;
  text-align: right;
}
.notif {
  width: 100%;
  min-height: 70px;
}
.personPic {
  /*display: inline-block;*/
  float: left;
  padding: 8px;
}
.personPic .MuiAvatar-root {
  width: 40px !important;
  height: 40px !important;
}
.hasProfile .MuiAvatar-root {
  width: 35px !important;
  height: 35px !important;
}
.notifData {
  display: inline-block;
  width: 69%;
  font-size: 12px;
  padding: 10px;
}
.notifs .dropdown-menu {
  transform: translate3d(-247px, 60px, 0px) !important;
  width: 310px;
  border-radius: 8px;
}
.notifs .dropdown-menu::before {
  display: inline-block;
  margin-left: 15.7em;
  vertical-align: 0.255em;
  content: "";
  position: absolute;
  margin-top: -1.34rem;
  border-right: 1em solid transparent;
  border-bottom: 1.3em solid #fff;
  border-left: 1em solid transparent;
}
.notifData p {
  font-weight: 400;
  margin-bottom: 8px;
}
.notifData hr {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 2px solid #d6d4d4;
  margin-left: 12px;
  width: 90%;
  display: block;
}
.notifs .dropdown-item {
  line-height: initial;
  display: block;
  width: 100%;
  padding: 0.1rem 0.1rem !important;
  color: #212529;
  text-align: inherit;
  white-space: pre-wrap !important;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
  background-color: #fff;
  color: #000000 !important;
}
.headerUsername {
  color: #a4a3b0 !important;
}
.navbar .dash {
  border-left: 1px solid #000;
  height: 35px;
  display: inline-block;
  position: absolute;
  top: 14px;
}
.navbar .anticon-search,
.navbar .ant-select-arrow {
  color: #a4a3b0 !important;
}
.navbar .ant-input::placeholder {
  color: #a4a3b0 !important;
  font-size: 13px !important;
  font-weight: 600;
}
.navbar .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.navbar
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  font-size: 12px !important;
  color: #a4a3b0;
  text-align: center;
}
.isActive {
  color: #c1b875 !important;
}
.popover-enter-done {
  overflow-y: scroll !important;
  height: 300px !important;
}
.hasProfile .popover-enter-done {
  overflow: hidden !important;
  height: 70px !important;
  text-align: center !important;
}
.hasProfile .dropdown-divider {
  height: 0;
  margin: 5px auto !important;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.topInbox .popover-enter-done {
  overflow: hidden !important;
  height: 30px !important;
}
.popover-enter-done::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(207, 207, 207, 0);
}

.popover-enter-done::-webkit-scrollbar-thumb {
  background: #90a4ae;
  border-radius: 5px;
}
.nav-item .btn .badge {
  top: -11px !important;
  font-size: 11px !important;
}
nav .btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent !important;
}
nav .btn-secondary:not([disabled]):not(.disabled):hover,
.btn-secondary:not([disabled]):not(.disabled).hover,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent !important;
}
.btn-secondary.dropdown-toggle {
  background-color: transparent !important;
}
nav .btn-blue-grey:not([disabled]):not(.disabled):active,
.btn-blue-grey:not([disabled]):not(.disabled).active,
.show > .btn-blue-grey.dropdown-toggle {
  background-color: transparent !important;
}
nav .btn-blue-grey:not([disabled]):not(.disabled):hover,
.btn-blue-grey:not([disabled]):not(.disabled).hover,
.show > .btn-blue-grey.dropdown-toggle {
  background-color: transparent !important;
}
.btn-blue-grey.dropdown-toggle {
  background-color: transparent !important;
}
.btn-blue-grey.dropdown-toggle,
.btn-blue-grey,
.btn-outline-blue-grey {
  background-color: transparent !important;
}
.nav-item .btn-blue-grey.dropdown-toggle,
.nav-item .btn-default,
.nav-item .btn-blue-grey.dropdown-toggle:focus {
  background-color: transparent !important;
}

@media (max-width: 1294px) {
  .rightUl {
    padding-left: 10% !important;
  }
}
@media (max-width: 1196px) {
  .rightUl {
    padding-left: 4% !important;
  }
}

@media (max-width: 1136px) {
  .rightUl {
    padding-left: 0% !important;
  }
}
@media (max-width: 1100px) {
  .rightUl {
    padding-left: 0% !important;
  }
  .rightUl .nav-item {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
}
@media (max-width: 1100px) {
  .rightUl {
    padding-left: 0% !important;
  }
  .nav-item .ant-input-affix-wrapper-lg {
    width: 10rem;
  }
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: unset;
  background: #fff !important;
  z-index: 3;
}
.personPic .MuiAvatar-fallback {
  margin: 4px !important;
}
