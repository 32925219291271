.searchCountainer{
    height: 305px;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
}
.searchCountainer::-webkit-scrollbar {
    width: 14px;
}
.searchCountainer::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
.searchCountainer::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
}
.search .ant-checkbox-wrapper {
    padding: 0px 20px;
}
.search .ant-checkbox {
    float: right;
}
.search .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    font-weight: 600;
    font-size: 15px;
}
/*.search #theSearch{
    width: 100%;
    border: 1px solid #dedede;
    height: 40px;
    padding: 10px;
}*/