.login{
    background-color: #fff;
    
}
.loginContainer{
    max-width: 100% !important;
}
.loginLeft{
    /*border: 1px solid ;*/
    width: 100%;
    height: 657px;
    background-image: url('../../assets/images/Group\ 244.png');
    background-size: cover;
}
.loginRight{
    /*border: 1px solid ;*/
    width: 100%;
    height: 650px;
    background-color: #fff;
    padding: 50px 90px;
    
}
.noPadding{
    padding: 0;
}
.loginRight .btn{
    background-color: #43425D !important;
}
.login .forgetP{
    text-align: center;
    color: #43425D;
    font-weight: 500;
    font-size: 18px
}

