.Receipt
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 40px !important;
}
@font-face {
  font-family: "Almarai";
  src: local("Almarai"), url("font.ttf") format("truetype");
}

@media (max-width: 1270px) {
  .fromSpan {
    margin-right: 0;
  }
}

.ant-layout::-webkit-scrollbar {
  width: 14px;
}

.ant-layout::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.ant-layout::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.receiptTable {
  overflow-x: scroll;
  white-space: nowrap;
}
.receiptTable::-webkit-scrollbar {
  width: 14px;
}

.receiptTable::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.receiptTable::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.receiptTable::-webkit-scrollbar {
  width: 14px;
}
