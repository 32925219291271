.salesManRecProduct{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background: #f8f8f8d8;
    padding: 40px 50px;
    margin: 2rem 1rem;
    margin-bottom: 20px;
    cursor: pointer;
}
.spanRec{
    cursor: pointer;
    border: 2px solid;
    background: #fff !important;
    width: 21px;
    height: 21px;
    display: inline-block;
    color: #3B86FF;
    font-weight: 600;
    border-radius: 6px;
    line-height: 4px;
    margin: 0 1rem 0 0rem;
    text-align: center;
}
.spanRec1:active {
    background: red !important;
}
.spanRec2:active {
    background: rgb(30, 255, 0) !important;
}
.centerDiv{
    display: inline-block;
    color: #3B86FF;
    font-weight: 600;
    line-height: 4px;
    margin: 0px 2rem 1rem 1rem;
    text-align: center;
}
.centerSpan{
    line-height: 20px;
    font-size: 23px;
    font-weight: 400;
    position: absolute;
    margin-top: 5px;
    margin: 0px -5px;
    display: inline-block;
}