.MuiListItem-button:hover {
  color: #fff !important;
}
.MuiListItem-button:hover .material-icons {
  color: #fff !important;
}
.MuiListItem-button:hover {
  background-color: #9830b041 !important;
}
.MuiIconButton-label {
  color: #fff !important;
}
.MuiButtonBase-root:hover .MuiIconButton-label {
  color: #fff !important;
}
.makeStyles-drawerOpen-8 {
  z-index: 100000 !important;
}
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: transparent !important;
  box-shadow: none !important;
}

.logo {
  height: 65px;
  padding: 10px;
  margin: 0px;
  background: #31313f;
}
.logo p {
  font-family: system-ui;
  color: #fff;
  font-size: 42px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 8px;
  margin-bottom: 0px;
  position: relative;
  margin-top: -10px;
}
.menuIconImg {
  width: 18px;
  margin-right: 15px;
}
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 2px !important;
}
.ant-menu-inline .ant-menu-item {
  margin-top: 0px !important;
}
.ant-menu-item .anticon {
  font-size: 20px !important;
}
.ant-menu-item {
  font-size: 16px !important;
}
.menuTitle {
  /*/position: absolute;*/
  margin-top: 3px;
  padding-right: 15px;
}
.ant-menu-dark.ant-menu-vertical .ant-menu-item {
  left: 0;
  margin-left: -3px !important;
}
.ant-tooltip {
  min-width: 90px;
  text-align: center;
}
.ant-tooltip span {
  margin-top: -1px;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark {
  color: rgba(255, 255, 255, 0.65);
  background: #43425d !important;
  /*height: 530px;
    overflow-y: scroll;*/
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #43425d !important;
}

.ant-menu.ant-menu-dark::-webkit-scrollbar {
  width: 12px;
}

.ant-menu.ant-menu-dark::-webkit-scrollbar-track {
  background: #43425d;
}
.ant-menu.ant-menu-dark::-webkit-scrollbar-thumb {
  background-color: #31313f;
  border-radius: 6px;
  border: 1px solid var(--scrollbarBG);
}

.ant-layout-sider-children {
  background: #43425d !important;
  /*position: fixed;*/
}
.ant-layout-sider-trigger {
  background: #31313f !important;
  /*width: 208px !important*/
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #727088 !important;
  border-left: 5px solid #fff !important;
  height: 45px !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content{
  text-align: right;
}